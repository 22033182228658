import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'
import { Navigate } from 'react-router-dom'

const checkSubdomainAndRedirect = () => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    switch (subdomain) {
        case 'abcd':
            return React.lazy(() => import('views/auth-views/authentication/unsubscribe'));
        default:
            return React.lazy(() => import('views/auth-views/authentication/unsubscribe'));
    }
}

export const publicRoutes = [
    {
        key: 'unsubscribe',
        path: `/`,
        component: checkSubdomainAndRedirect(),
    },
]

export const protectedRoutes = []
