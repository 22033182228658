import { parse as tldts } from 'tldts';
const commonEnv = {
    ...process.env,
    "REACT_APP_DOMAIN": tldts(window.location.hostname).domain,
    "REACT_APP_PAGETITLE": "Admin Portal - CallHippo",
    "REACT_APP_LOGO": "/img/callhippo-logo.svg",
    "REACT_APP_LOGO_SMALL": "/img/callhippo-logo-sm.svg",
    "REACT_APP_LOGO_WHITE": "/img/callhippo-logo-white.svg",
    "REACT_APP_LOGO_SMALL_WHITE": "/img/callhippo-logo-sm-white.svg",
    "REACT_APP_AUTH_BACKGROUND": "/img/others/img-17-callhippo.png",
    "REACT_APP_FAVICON": "/callhippo-favicon.png",
    "REACT_APP_LOGO_ALT": "CallHippo",
    "REACT_APP_GOOGLE_SIGNIN_CLIENT_ID": "524143824291-69q0il5ojjek9su64ir4gtk7eruu8ijv.apps.googleusercontent.com",
    "REACT_APP_S3_URL_DOMAIN": "https://callhippo-media.s3.amazonaws.com",
}

const whiteLabelEnvLive = {};

whiteLabelEnvLive["localhost"] = {
    ...commonEnv,
    "REACT_APP_IS_CALLHIPPO": true,
};


whiteLabelEnvLive["unsubscribe.callhippo.com"] = {
    ...commonEnv,
    "REACT_APP_IS_CALLHIPPO": true,
    "REACT_APP_API_URL": "https://web.callhippo.com",
};

export default whiteLabelEnvLive;