import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import AuthService from 'services/AuthService';

export const initialState = {
	loading: false,
	message: '',
	errorMsg: '',
	showMessage: false,
	messageType: 'error',
	redirect: '',
	otpSent: false,
	userData: null,
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const unsubscribeSendOtp = createAsyncThunk('/unsubscribeSendOtp', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.unsubscribeSendOtp(data)

		console.log("🚀 ================== ~ file: authSlice.js:21 ~ unsubscribeSendOtp ~ response:", response);

		if (response.success === true) {
			return response.message;
		} else {

			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}

	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const unsubscribeVerifyOtp = createAsyncThunk('/unsubscribeVerifyOtp', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.unsubscribeVerifyOtp(data)

		console.log("🚀 ================== ~ file: authSlice.js:35 ~ unsubscribeVerifyOtp ~ response:", response);

		if (response.success) {
			console.log("🚀 ================== ~ file: authSlice.js:40 ~ unsubscribeVerifyOtp ~ response.message:", response.message);
			return response.message;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}


	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.login(data)
		if (response.data) {
			const data = response.data;
			localStorage.setItem(AUTH_TOKEN, data.authToken);
			return { token: data.authToken, userData: data };
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const authData = createAsyncThunk('auth/authData', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.authData(data)
		if (response.data) {
			const data = response.data;
			return data;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/signUp', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.register(data)
		if (response.user) {
			const token = response.user.refreshToken;
			localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
			return token;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/signOut', async () => {
	const response = await AuthService.logout()
	localStorage.removeItem(AUTH_TOKEN);
	return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (data, { rejectWithValue }) => {
	try {
		const response = await AuthService.signInWithGoogle(data)
		if (response.data) {
			const data = response.data;
			localStorage.setItem(AUTH_TOKEN, data.authToken);
			return { token: data.authToken, userData: data };
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(unsubscribeSendOtp.pending, (state) => {
				state.loading = true
			})
			.addCase(unsubscribeSendOtp.fulfilled, (state, action) => {
				state.message = action.payload
				state.messageType = 'success'
				state.showMessage = true
				state.loading = false
				state.otpSent = true
			})
			.addCase(unsubscribeSendOtp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				state.messageType = 'error'
			})
			.addCase(unsubscribeVerifyOtp.pending, (state) => {
				state.loading = true
			})
			.addCase(unsubscribeVerifyOtp.fulfilled, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				state.messageType = 'success'
				state.otpSent = false
			})
			.addCase(unsubscribeVerifyOtp.rejected, (state, action) => {
				state.errorMsg = action.payload
				state.showMessage = true
				state.loading = false
				state.messageType = 'error'
			})
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userData = action.payload.userData
				state.messageType = 'error'
				state.otpSent = false
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				state.messageType = 'error'
			})
			.addCase(authData.pending, (state) => {
				state.loading = true
			})
			.addCase(authData.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.userData = action.payload
				state.messageType = 'error'
				state.otpSent = false
			})
			.addCase(authData.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				state.messageType = 'error'
			})
			.addCase(signOut.fulfilled, (state) => {
				state.showMessage = false
				state.loading = false
				state.token = null
				state.userData = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.showMessage = false
				state.loading = false
				state.token = null
				state.userData = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.userData = action.payload.userData
				state.messageType = 'error'
				state.otpSent = false
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
				state.messageType = 'error'
			})
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer