import whiteLabelEnvLive from "./whiteLabelEnvLive";

const whiteLabelEnvTest = {};

whiteLabelEnvTest["localhost"] = {
    ...whiteLabelEnvLive["localhost"],
    "REACT_APP_API_URL": "https://web.callhippo.com",
};

whiteLabelEnvTest["dev-unsubscribe.callhippo.com"] = {
    ...whiteLabelEnvLive["unsubscribe.callhippo.com"],
    "REACT_APP_API_URL": "https://web.callhippo.com",
};

export default whiteLabelEnvTest;